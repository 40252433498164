import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { useAppSelector } from '../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectCurrentPointActive,
  selectCurrentYear,
} from '../../store/mainReducer';

import Ligne400kv from '../../assets/images/points/left/ligne400kv.svg';
import Ligne225kv from '../../assets/images/points/left/ligne225kv.svg';
import Post from '../../assets/images/points/left/post.svg';
import Ligne225kvCtr from '../../assets/images/points/left/ligne225kvCtr.svg';
import Ligne400kvCtr from '../../assets/images/points/left/ligne400kvCtr.svg';
import Industry from '../../assets/images/points/left/indust.svg';
import ZoneConso from '../../assets/images/points/left/zoneConso.svg';
import ECarburant from '../../assets/images/points/left/E-Carburant.svg';
import DécarbonationHydrogène from '../../assets/images/points/left/DécarbonationHydrogène.svg';
import ligne225SOUSCRT from '../../assets/images/points/left/ligne225SOUSCRT.svg';
import ligne400SOUSCRT from '../../assets/images/points/left/ligne400SOUSCRT.svg';

import ligne225Adapt from '../../assets/images/points/left/ligne225Adapt.svg';
import ligne400Adapt from '../../assets/images/points/left/ligne400Adapt.svg';
import Futur400Adapt from '../../assets/images/points/left/Futur400Adapt.svg';
import Futur225Adapt from '../../assets/images/points/left/Futur225Adapt.svg';
import PostAdapt from '../../assets/images/points/left/PostAdapt.svg';
import ZoneConsoAdapt from '../../assets/images/points/left/ZoneConsoAdapt.svg';
import Ouvrage from '../../assets/images/points/left/Ouvrage.svg';
import FuturExtention from '../../assets/images/points/left/FuturExtention.svg';
import Poste_P2 from '../../assets/images/points/left/Poste_P2.svg';
import zoneFuturOv400 from '../../assets/images/points/left/zoneFuturOv400.svg';
import PosteSature from '../../assets/images/points/left/PosteSature.svg';
import ElectUsed from '../../assets/images/points/left/ElectUsed.svg';

const StyledDiv = styled.div`
  color: rgba(255, 255, 255, 1);
  background-color: rgba(10, 90, 114, 1);
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  padding-left: 0.5rem;
  min-height: 25rem;
  .icon {
    width: 1.4rem;
  }
  .info {
    font-size: 0.8rem;
  }
`;

const ItemLegendPoint = () => {
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const categoryPointIn = useAppSelector(selectCategoryActivePointIn);
  const currentYear = useAppSelector(selectCurrentYear);

  return (
    <StyledDiv>
      <div className="col-12  mb-1 mt-3">
        <h5>{'Légende :'}</h5>
      </div>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2024' ||
            (currentYear === 'YEAR_2030' && currentPointActive === 'SSFOS')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ligne225kv} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Ligne 225 kV'}</div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2024' ||
            (currentYear === 'YEAR_2030' && currentPointActive === 'SSFOS')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ligne400kv} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Ligne 400 kV'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' && categoryPointIn === 'pas de click'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ligne225kvCtr} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 225 kV sous contrainte'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' && categoryPointIn === 'pas de click'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ligne400kvCtr} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 400 kV sous contrainte'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' && categoryPointIn !== 'pas de click'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ligne225SOUSCRT} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 225 kV sous contrainte'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' && categoryPointIn !== 'pas de click'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ligne400SOUSCRT} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 400 kV sous contrainte'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2024' || currentYear === 'YEAR_2030'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Post} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Poste'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' && currentPointActive === 'SSHAVRE'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img
            className="icon"
            src={PosteSature}
            alt={''}
            width={18}
            height={18}
          />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Poste saturé'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2024' || currentYear === 'YEAR_2030'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ZoneConso} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Zone de forte consommation'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' &&
            (categoryPointIn === 'pas de click' || categoryPointIn === 'co2h2')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={DécarbonationHydrogène} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Hydrogène / Décarbonation'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === 'industrie')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Industry} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Industrialisation'}</div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSHAVRE' &&
            currentYear === 'YEAR_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === 'ecarburant')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ECarburant} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Production d’e-carburant'}
          </div>
        </div>
      </Row>
      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSFOS' &&
            currentYear === 'YEAR_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === 'electrification')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ElectUsed} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'électrification des usages'}
          </div>
        </div>
      </Row>
      {/* <Row
        className="align-items-center mb-2"
        style={{
          display: currentYear === 'YEAR_ADAPT_2030' ? 'flex' : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ligne225Adapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Ligne 225 kV'}</div>
        </div>
      </Row> */}

      <Row
        className="align-items-center mb-2"
        style={{
          display: currentYear === 'YEAR_ADAPT_2030' ? 'flex' : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ligne400Adapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Ligne 400 kV'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSHAVRE' &&
            currentYear === 'YEAR_ADAPT_2030' &&
            (categoryPointIn === 'pas de click' || categoryPointIn === '225')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Futur225Adapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Futur ouvrage 225 kV'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_ADAPT_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === '400 - palier 1')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Futur400Adapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Futur ouvrage 400 kV'}
          </div>
        </div>
      </Row>

      {/* <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_ADAPT_2030' && categoryPointIn === 'transfo'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Futur400Adapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Renforcement ligne 400 kV'}
          </div>
        </div>
      </Row> */}

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSFOS' &&
            currentYear === 'YEAR_ADAPT_2030' &&
            (categoryPointIn === 'transfo' ||
              categoryPointIn === '400 - palier 2')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ouvrage} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ouvrage 400 kV réalisé'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSFOS' &&
            currentYear === 'YEAR_ADAPT_2030' &&
            categoryPointIn === 'transfo'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ligne400SOUSCRT} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 400 kV sous contrainte'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSFOS' &&
            currentYear === 'YEAR_ADAPT_2030' &&
            categoryPointIn === '400 - palier 1'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Ligne400kvCtr} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Ligne 400 kV sous contrainte'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display: currentYear === 'YEAR_ADAPT_2030' ? 'flex' : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={PostAdapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Poste'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_ADAPT_2030' &&
            categoryPointIn === '400 - palier 2'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={Poste_P2} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Extention poste réalisée'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_ADAPT_2030' &&
            currentPointActive === 'SSFOS' &&
            categoryPointIn === 'transfo'
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={FuturExtention} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Future extension de poste'}
          </div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentPointActive === 'SSHAVRE' &&
            currentYear === 'YEAR_ADAPT_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === 'transfo')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={FuturExtention} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Nouveau poste'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display:
            currentYear === 'YEAR_ADAPT_2030' &&
            (categoryPointIn === 'pas de click' ||
              categoryPointIn === '400 - palier 2')
              ? 'flex'
              : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={zoneFuturOv400} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">{'Zone ouvrage 400 kv'}</div>
        </div>
      </Row>

      <Row
        className="align-items-center mb-2"
        style={{
          display: currentYear === 'YEAR_ADAPT_2030' ? 'flex' : 'none',
        }}
      >
        <div className="col-2 text-center">
          <img className="icon" src={ZoneConsoAdapt} alt={''} />
        </div>
        <div className="col-8">
          <div className="align-items-center info">
            {'Zone de forte consommation'}
          </div>
        </div>
      </Row>
    </StyledDiv>
  );
};

export default ItemLegendPoint;
