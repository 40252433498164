import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
  selectToggleFluxPrincipal,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { getSizeIcon, splitYear } from '../../../utils';

import IconsMap from '../../../assets/images/goulet/IconsMap.png';


const useFluxGouletLayer = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const checkEdge = userAgent.includes("edg");
console.log(userAgent.includes("edg"), userAgent);

  // Define icon mappings for normal and hover states
const iconMapping: any = {
  B_2030_EJCS_flux: { x: 1298, y: 12, width: 310, height: 437, mask: false, anchorX : 148, anchorY : 240 },
  B_2035_EJCS_flux: { x: 1298, y: 12, width: 310, height: 437, mask: false, anchorX : 148, anchorY : 240 },
  B_2040_EJCS_flux: { x: 1298, y: 12, width: 310, height: 437, mask: false, anchorX : 148, anchorY : 240 },
 
  B_2030_HNVC_flux: { x: 11, y: 518, width: 320, height: 235, mask: false, anchorX : checkEdge ? 116 : 118, anchorY : checkEdge ? 140 : 135 },
  B_2035_HNVC_flux: { x: 100, y: 2, width: 320, height: 235, mask: false, anchorX : checkEdge ? 97 : 99, anchorY : checkEdge ? 139 : 134 },
  B_2040_HNVC_flux: { x: 14, y: 2, width: 372, height: 235, mask: false, anchorX : checkEdge ? 183 : 185, anchorY : checkEdge ? 139 : 134 },
  
  C_2030_EJCS_flux: { x: 2, y: 810, width: 288, height: 390, mask: false, anchorX : 118, anchorY : 204 },
  C_2030_HNVC_flux: { x: 1030, y: 2, width: 240, height: 447, mask: false, anchorX : 74, anchorY : 240 },
  C_2035_EJCS_flux: { x: 2, y: 810, width: 288, height: 390, mask: false, anchorX : 118, anchorY : 204 },
  C_2035_HNVC_flux: { x: 712, y: 3, width: 242, height: 418, mask: false, anchorX : 108, anchorY : 231 },
  C_2040_EJCS_flux: { x: 2, y: 810, width: 288, height: 390, mask: false, anchorX : 118, anchorY : 204 },
  C_2040_HNVC_flux: { x: 422, y: 2, width: 242, height: 418, mask: false, anchorX : 118, anchorY : 232 },

  E_2030_EJCS_flux: { x: 945, y: 1620, width: 260, height: 224, mask: false, anchorX : 132, anchorY : 106 },
  E_2030_HNVC_flux: { x: 2, y: 1893, width: 298, height: 526, mask: false, anchorX : 120, anchorY : 278 },
  E_2035_EJCS_flux: { x: 945, y: 1620, width: 260, height: 224, mask: false, anchorX : 132, anchorY : 106 },
  E_2035_HNVC_flux: { x: 360, y: 1908, width: 400, height: 526, mask: false, anchorX : 220, anchorY : 270 },
  E_2040_EJCS_flux: { x: 945, y: 1620, width: 260, height: 224, mask: false, anchorX : 132, anchorY : 106 },
  E_2040_HNVC_flux: { x: 802, y: 1896, width: 400, height: 526, mask: false, anchorX : 218, anchorY : 275 },

  I_2030_EJCS_flux: { x: 1230, y: 1870, width: 328, height: 241, mask: false, anchorX : 170, anchorY : 126 },
  I_2030_HNVC_flux: { x: 1230, y: 1870, width: 328, height: 241, mask: false, anchorX : 57, anchorY : 98 },
  I_2035_EJCS_flux: { x: 1230, y: 1870, width: 328, height: 241, mask: false, anchorX : 170, anchorY : 126 },
  I_2035_HNVC_flux: { x: 4, y: 2450, width: 545, height: 241, mask: false, anchorX : 276, anchorY : 99 },
  I_2040_EJCS_flux: { x: 1230, y: 1870, width: 328, height: 241, mask: false, anchorX : 170, anchorY : 126 },
  I_2040_HNVC_flux: { x: 4, y: 2450, width: 545, height: 241, mask: false, anchorX : 276, anchorY : 99 },
};


  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);
  const active = useAppSelector(selectToggleFluxPrincipal);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_flux`;
      return categorie.startsWith(prefix);
    });
  };

  const layerFlux = new IconLayer({
    id: 'flux-layer',
    data: getSymbolData(),
    getPosition: (d: any) => {
      return d.position;
    },
    getIcon: (d: any) =>  d.properties.categorie,
    getColor: [255, 255, 0],
    iconAtlas: IconsMap, // Your image containing all icons
    iconMapping,
    sizeUnits: 'pixels',
    getSize: (d) =>  getSizeIcon(d.properties.categorie, iconMapping),
    pickable: true,
    visible: active
  });

  return [layerFlux];
};

export default useFluxGouletLayer;
